import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageProps, TagGroup } from '../types'
import { TagList } from '../components/taglist'

type DataProps = PageProps<{
  allMarkdownRemark: TagGroup
}>

const CategoriesPage: React.FC<DataProps> = ({
  location,
  data: {
    allMarkdownRemark: { group },
    site,
    pages
  }
}) => (
  <Layout
    pages={pages}
    location={location}
    title="All Categories"
    site={site}
    nav={[{ href: '/categories', title: 'Categories' }]}>
    <SEO title="All Categories" />
    <TagList group={group} prefix="categories" />
  </Layout>
)

export default CategoriesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`
